import {
  Status,
  ACTION_SUCCESS,
  RESOURCE_NOTFOUND,
  UNEXPECTED_ERROR,
} from "../Status"
import {
  SearchCategoryParam, SearchCategoryResult,
  GetCategoryParam, GetCategoryResult,
  SearchProductParam, SearchProductResult,
  GetProductParam, GetProductResult,
} from "../Product"

type Category = {
  name: string
  description?: string
  slug: string
  icon: any
}

type Product = {
  name: string
  description?: string
  slug: string
  thumbnail: any
  categories: string[]
  pds_file?: string
  sds_file?: string
  meta?: string
}

export class ProductService {

  private categories: Category[] = []
  private products: Product[] = []

  constructor() {
    this.categories = [
      {
        name: "Cylinder Oil",
        icon: "/image/cylinder-oil.jpg",
        slug: "cylinder-oil",
      },
      {
        name: "System Oil",
        icon: "/image/system-oil.jpg",
        slug: "system-oil",
      },
      {
        name: "Engine Oil",
        icon: "/image/engine-oil.jpg",
        slug: "engine-oil",
      },
      {
        name: "Hydraulic Oil",
        icon: "/image/hydraulic-oil.jpg",
        slug: "hydraulic-oil",
      },
      {
        name: "Gear Oil",
        icon: "/image/gear-oil.jpg",
        slug: "gear-oil",
      },
      {
        name: "Turbine Oil",
        icon: "/image/turbine-oil.jpg",
        slug: "turbine-oil",
      },
      {
        name: "Air Compressor Oil",
        icon: "/image/air-compressor-oil.jpg",
        slug: "air-compressor-oil",
      },
      {
        name: "Ref Compressor Oil",
        icon: "/image/refrigeration-compressor-oil.jpg",
        slug: "refrigeration-compressor-oil",
      },
      {
        name: "Greases",
        icon: "/image/greases.jpg",
        slug: "greases",
      }
    ]
    this.products = [
      {
        name: "Eni Punica 540 (Sae 50)",
        thumbnail: "/image/cylinder-oil.png",
        slug: "eni-punica-540-sae-50",
        categories: ["cylinder-oil"],
        pds_file: "/file/PDS - PUNICA 540 SAE 50.pdf",
        sds_file: "/file/SDS - ENI PUNICA 540 (SAE 50).pdf",
        meta: "Eni Punica 540 (Sae 50) Cylinder oil",
      },
      {
        name: "Eni Punica 570 (Sae 50)",
        thumbnail: "/image/cylinder-oil.png",
        slug: "eni-punica-570-sae-50",
        categories: ["cylinder-oil"],
        pds_file: "/file/PDS - PUNICA 570 SAE 50.pdf",
        sds_file: "/file/SDS - ENI PUNICA 570 (SAE 50).pdf",
        meta: "Eni Punica 570 (Sae 50) Cylinder oil",
      },
      {
        name: "Eni Punica 5100 (Sae 50)",
        thumbnail: "/image/cylinder-oil.png",
        slug: "eni-punica-5100-sae-50",
        categories: ["cylinder-oil"],
        pds_file: "/file/PDS - PUNICA 5100 SAE 50.pdf",
        sds_file: "/file/SDS - ENI PUNICA 5100 (SAE 50).pdf",
        meta: "Eni Punica 5100 (Sae 50) Cylinder oil",
      },
      {
        name: "Eni Cladium 50 (Sae 30)",
        thumbnail: "/image/system-oil.png",
        slug: "eni-cladium-50-sae-30",
        categories: ["system-oil"],
        pds_file: "/file/PDS - CLADIUM 50 SAE 30.pdf",
        sds_file: "/file/SDS - ENI CLADIUM 50 (SAE 30).pdf",
        meta: "Eni Cladium 50 (Sae 30) System oil",
      },
      {
        name: "Eni Cladium 120 (Sae 30)",
        thumbnail: "/image/engine-oil.png",
        slug: "eni-cladium-120-sae-30",
        categories: ["engine-oil"],
        pds_file: "/file/PDS - CLADIUM 120 SAE 30.pdf",
        sds_file: "/file/SDS - ENI CLADIUM 120 (SAE 30).pdf",
        meta: "Eni Cladium 120 (Sae 30) Engine oil",
      },
      {
        name: "Eni Cladium 120 (Sae 40)",
        thumbnail: "/image/engine-oil.png",
        slug: "eni-cladium-120-sae-40",
        categories: ["engine-oil"],
        pds_file: "/file/PDS - CLADIUM 120 SAE 40.pdf",
        sds_file: "/file/SDS - ENI CLADIUM 120 (SAE 40).pdf",
        meta: "Eni Cladium 120 (Sae 40) Engine oil",
      },
      {
        name: "Eni Cladium 300 (Sae 30)",
        thumbnail: "/image/engine-oil.png",
        slug: "eni-cladium-300-sae-30",
        categories: ["engine-oil"],
        pds_file: "/file/PDS - CLADIUM 300 SAE 30.pdf",
        sds_file: "/file/SDS - ENI CLADIUM 300 (SAE 30).pdf",
        meta: "Eni Cladium 300 (Sae 30) Engine oil",
      },
      {
        name: "Eni Cladium 300 (Sae 40)",
        thumbnail: "/image/engine-oil.png",
        slug: "eni-cladium-300-sae-40",
        categories: ["engine-oil"],
        pds_file: "/file/PDS - CLADIUM 300 SAE 40.pdf",
        sds_file: "/file/SDS - ENI CLADIUM 300 (SAE 40).pdf",
        meta: "Eni Cladium 300 (Sae 40) Engine oil",
      },
      {
        name: "Eni Cladium 400 (Sae 40)",
        thumbnail: "/image/engine-oil.png",
        slug: "eni-cladium-400-sae-40",
        categories: ["engine-oil"],
        pds_file: "/file/PDS - CLADIUM 400 SAE 40.pdf",
        sds_file: "/file/SDS - ENI CLADIUM 400 (SAE 40).pdf",
        meta: "Eni Cladium 400 (Sae 40) Engine oil",
      },
      {
        name: "Eni Cladium 200 (Sae 30)",
        thumbnail: "/image/engine-oil.png",
        slug: "eni-cladium-200-sae-30",
        categories: ["engine-oil"],
        pds_file: "/file/PDS - CLADIUM 200 SG SAE 30-E9005-2024.6.14.pdf",
        sds_file: "/file/SDS - Eni CLADIUM 200 (SAE 30).pdf",
        meta: "Eni Cladium 200 (Sae 30) Engine oil",
      },
      {
        name: "Eni Cladium 200 (Sae 40)",
        thumbnail: "/image/engine-oil.png",
        slug: "eni-cladium-200-sae-40",
        categories: ["engine-oil"],
        pds_file: "/file/PDS - CLADIUM 200 SAE 40.pdf",
        sds_file: "/file/SDS - ENI CLADIUM 200 (SAE 40).pdf",
        meta: "Eni Cladium 200 (Sae 40) Engine oil",
      },
      {
        name: "Eni I-Sigma Superfleet 15w40",
        thumbnail: "/image/engine-oil.png",
        slug: "eni-i-sigma-superfleet-15w40",
        categories: ["engine-oil"],
        pds_file: "/file/PDS - ENI i-Sigma Super Fleet 15W-40.pdf",
        sds_file: "/file/SDS - ENI i-SIGMA SUPER FLEET 15W-40.pdf",
        meta: "Eni I-Sigma Superfleet 15w40 Engine oil",
      },
      {
        name: "Eni Arnica Tec 15",
        thumbnail: "/image/hydraulic-oil.png",
        slug: "eni-arnica-tec-15",
        categories: ["hydraulic-oil"],
        pds_file: "/file/PDS - ARNICA TEC 15.pdf",
        sds_file: "/file/SDS - ENI ARNICA TEC (ISO VG 15)(4786-15)_Gold.pdf",
        meta: "Eni Arnica Tec 15 Hydraulic oil",
      },
      {
        name: "Eni Arnica Tec 32",
        thumbnail: "/image/hydraulic-oil.png",
        slug: "eni-arnica-tec-32",
        categories: ["hydraulic-oil"],
        pds_file: "/file/PDS - ARNICA TEC 32.pdf",
        sds_file: "/file/SDS - ENI ARNICA TEC (ISO VG 32)(32-2736)_Gold.pdf",
        meta: "Eni Arnica Tec 32 Hydraulic oil",
      },
      {
        name: "Eni Arnica Tec 46",
        thumbnail: "/image/hydraulic-oil.png",
        slug: "eni-arnica-tec-46",
        categories: ["hydraulic-oil"],
        pds_file: "/file/PDS - ARNICA TEC 46.pdf",
        sds_file: "/file/SDS - ENI ARNICA TEC (ISO VG 46)(4778-98)_Gold.pdf",
        meta: "Eni Arnica Tec 46 Hydraulic oil",
      },
      {
        name: "Eni Arnica Tec 68",
        thumbnail: "/image/hydraulic-oil.png",
        slug: "eni-arnica-tec-68",
        categories: ["hydraulic-oil"],
        pds_file: "/file/PDS - ARNICA TEC 68.pdf",
        sds_file: "/file/SDS - ENI ARNICA TEC (ISO VG 68)(32-2948)_Gold.pdf",
        meta: "Eni Arnica Tec 68 Hydraulic oil",
      },
      {
        name: "Eni Arnica Tec 100",
        thumbnail: "/image/hydraulic-oil.png",
        slug: "eni-arnica-tec-100",
        categories: ["hydraulic-oil"],
        pds_file: "/file/PDS - ARNICA TEC 100.pdf",
        sds_file: "/file/SDS - ENI ARNICA TEC (ISO VG 100)(4786-17)_Gold.pdf",
        meta: "Eni Arnica Tec 100 Hydraulic oil",
      },
      {
        name: "Eni Blasia Ml 68",
        thumbnail: "/image/gear-oil.png",
        slug: "eni-blasia-ml-68",
        categories: ["gear-oil"],
        pds_file: "/file/PDS - BLASIA ML (SERIES).pdf",
        meta: "Eni Blasia Ml 68 Gear oil",
      },
      {
        name: "Eni Blasia Ml 100",
        thumbnail: "/image/gear-oil.png",
        slug: "eni-blasia-ml-100",
        categories: ["gear-oil"],
        pds_file: "/file/PDS - BLASIA ML (SERIES).pdf",
        meta: "Eni Blasia Ml 100 Gear oil",
      },
      {
        name: "Eni Blasia Ml 150",
        thumbnail: "/image/gear-oil.png",
        slug: "eni-blasia-ml-150",
        categories: ["gear-oil"],
        pds_file: "/file/PDS - BLASIA ML (SERIES).pdf",
        sds_file: "/file/SDS - ENI BLASIA ML 150.pdf",
        meta: "Eni Blasia Ml 150 Gear oil",
      },
      {
        name: "Eni Blasia Ml 220",
        thumbnail: "/image/gear-oil.png",
        slug: "eni-blasia-ml-220",
        categories: ["gear-oil"],
        pds_file: "/file/PDS - BLASIA ML (SERIES).pdf",
        sds_file: "/file/SDS - ENI BLASIA ML 220.pdf",
        meta: "Eni Blasia Ml 220 Gear oil",
      },
      {
        name: "Eni Blasia Ml 320",
        thumbnail: "/image/gear-oil.png",
        slug: "eni-blasia-ml-320",
        categories: ["gear-oil"],
        pds_file: "/file/PDS - BLASIA ML (SERIES).pdf",
        meta: "Eni Blasia Ml 320 Gear oil",
      },
      {
        name: "Eni Blasia Ml 460",
        thumbnail: "/image/gear-oil.png",
        slug: "eni-blasia-ml-460",
        categories: ["gear-oil"],
        pds_file: "/file/PDS - BLASIA ML (SERIES).pdf",
        meta: "Eni Blasia Ml 460 Gear oil",
      },
      {
        name: "Eni Blasia Sx 220 (Syn)",
        thumbnail: "/image/gear-oil.png",
        slug: "eni-blasia-sx-220-syn",
        categories: ["gear-oil"],
        pds_file: "/file/PDS - BLASIA SX 220.pdf",
        sds_file: "/file/SDS - ENI BLASIA SX 220.pdf",
        meta: "Eni Blasia Sx 220 (Syn) Gear oil",
      },
      {
        name: "Eni Ote 68",
        thumbnail: "/image/turbine-oil.png",
        slug: "eni-ote-68",
        categories: ["turbine-oil"],
        pds_file: "/file/PDS - OTE 68.pdf",
        sds_file: "/file/SDS - ENI OTE TEC (ISO VG 68).pdf",
        meta: "Eni Ote 68 Turbine oil",
      },
      {
        name: "Eni Dicrea 32",
        thumbnail: "/image/air-compressor-oil.png",
        slug: "eni-dicrea-32",
        categories: ["air-compressor-oil"],
        pds_file: "/file/PDS - DICREA 32.pdf",
        sds_file: "/file/SDS - ENI DICREA 32.pdf",
        meta: "Eni Dicrea 32 Air compressor oil",
      },
      {
        name: "Eni Dicrea 46",
        thumbnail: "/image/air-compressor-oil.png",
        slug: "eni-dicrea-46",
        categories: ["air-compressor-oil"],
        pds_file: "/file/PDS - DICREA 46.pdf",
        sds_file: "/file/SDS - ENI DICREA 46.pdf",
        meta: "Eni Dicrea 46 Air compressor oil",
      },
      {
        name: "Eni Dicrea 100",
        thumbnail: "/image/air-compressor-oil.png",
        slug: "eni-dicrea-100",
        categories: ["air-compressor-oil"],
        pds_file: "/file/PDS - DICREA 100.pdf",
        sds_file: "/file/SDS - ENI DICREA 100.pdf",
        meta: "Eni Dicrea 100 Air compressor oil",
      },
      {
        name: "Eni Dicrea Sx 46 (Syn)",
        thumbnail: "/image/air-compressor-oil.png",
        slug: "eni-dicrea-sx-46-syn",
        categories: ["air-compressor-oil"],
        pds_file: "/file/PDS - DICREA SX 46.pdf",
        sds_file: "/file/SDS - ENI DICREA SX 46.pdf",
        meta: "Eni Dicrea Sx 46 (Syn) Air compressor oil",
      },
      {
        name: "Eni Dicrea Sx 68 (Syn)",
        thumbnail: "/image/air-compressor-oil.png",
        slug: "eni-dicrea-sx-68-syn",
        categories: ["air-compressor-oil"],
        pds_file: "/file/PDS - DICREA SX 68.pdf",
        sds_file: "/file/SDS - ENI DICREA SX 68.pdf",
        meta: "Eni Dicrea Sx 68 (Syn) Air compressor oil",
      },
      {
        name: "Eni Dicrea Esx 100 (Syn)",
        thumbnail: "/image/air-compressor-oil.png",
        slug: "eni-dicrea-esx-100-syn",
        categories: ["air-compressor-oil"],
        pds_file: "/file/PDS - DICREA ESX 100.pdf",
        sds_file: "/file/SDS - ENI DICREA ESX 100.pdf",
        meta: "Eni Dicrea Esx 100 (Syn) Air compressor oil",
      },
      {
        name: "Eni Betula 46",
        thumbnail: "/image/refrigeration-compressor-oil.png",
        slug: "eni-betula-46",
        categories: ["refrigeration-compressor-oil"],
        pds_file: "/file/PDS - BETULA 46.pdf",
        meta: "Eni Betula 46 Ref Refrigeration compressor oil",
      },
      {
        name: "Eni Betula 68",
        thumbnail: "/image/refrigeration-compressor-oil.png",
        slug: "eni-betula-68",
        categories: ["refrigeration-compressor-oil"],
        pds_file: "/file/PDS - BETULA 68.pdf",
        sds_file: "/file/SDS - ENI BETULA 68.pdf",
        meta: "Eni Betula 68 Ref Refrigeration compressor oil",
      },
      {
        name: "Eni Betula Esx 46",
        thumbnail: "/image/refrigeration-compressor-oil.png",
        slug: "eni-betula-esx-46",
        categories: ["refrigeration-compressor-oil"],
        pds_file: "/file/PDS - BETULA ESX 46.pdf",
        sds_file: "/file/SDS - ENI BETULA ESX 46.pdf",
        meta: "Eni Betula Esx 46 Ref Refrigeration compressor oil",
      },
      {
        name: "Eni Betula Esx 68",
        thumbnail: "/image/refrigeration-compressor-oil.png",
        slug: "eni-betula-esx-68",
        categories: ["refrigeration-compressor-oil"],
        pds_file: "/file/PDS - BETULA ESX 68.pdf",
        sds_file: "/file/SDS - ENI BETULA ESX 68.pdf",
        meta: "Eni Betula Esx 68 Ref Refrigeration compressor oil",
      },
      {
        name: "Eni Betula Esx 100",
        thumbnail: "/image/refrigeration-compressor-oil.png",
        slug: "eni-betula-esx-100",
        categories: ["refrigeration-compressor-oil"],
        pds_file: "/file/PDS - BETULA ESX 100.pdf",
        sds_file: "/file/SDS - ENI BETULA ESX 100.pdf",
        meta: "Eni Betula Esx 100 Ref Refrigeration compressor oil",
      },
      {
        name: "Eni Grease Mu Ep 2",
        thumbnail: "/image/greases.png",
        slug: "eni-grease-mu-ep-2",
        categories: ["greases"],
        pds_file: "/file/PDS - GREASE MU EP 2.pdf",
        sds_file: "/file/SDS - ENI GREASE MU EP 2.pdf",
        meta: "Eni Grease Mu Ep 2 Grases",
      },
      {
        name: "Eni Grease Pv 2",
        thumbnail: "/image/greases.png",
        slug: "eni-grease-pv-2",
        categories: ["greases"],
        pds_file: "/file/PDS - GREASE PV 2.pdf",
        sds_file: "/file/SDS - ENI GREASE PV 2.pdf",
        meta: "Eni Grease Pv 2 Grases",
      },
      {
        name: "Eni Grease Lc 2",
        thumbnail: "/image/greases.png",
        slug: "eni-grease-lc-2",
        categories: ["greases"],
        pds_file: "/file/PDS - ENI GREASE LC 2.pdf",
        sds_file: "/file/SDS - ENI GREASE LC 2.pdf",
        meta: "Eni Grease Lc 2Grases",
      },
      {
        name: "Eni Grease Eco Plus 2",
        thumbnail: "/image/greases.png",
        slug: "eni-grease-eco-plus-2",
        categories: ["greases"],
        pds_file: "/file/PDS - GREASE ECO PLUS 2.pdf",
        sds_file: "/file/SDS - ENI GREASE ECO PLUS 2.pdf",
        meta: "Eni Grease Eco Plus 2 Grases",
      },
    ]
  }

  public async SearchCategory(p: SearchCategoryParam): Promise<SearchCategoryResult> {
    try {
      return {
        success: new Status("success search category", ACTION_SUCCESS),
        data: {
          items: this.categories
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async GetCategory(p: GetCategoryParam): Promise<GetCategoryResult> {
    try {
      const category = this.categories.find((category) => {
        return category.slug === p.slug
      })
      if (!category) {
        return {
          error: new Status("category not available", RESOURCE_NOTFOUND),
        }
      }

      return {
        success: new Status("success get category", ACTION_SUCCESS),
        data: category
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async SearchProduct(p: SearchProductParam): Promise<SearchProductResult> {
    try {
      const {
        page = 1,
        total_items = 12,
        categories = [],
        keyword,
      } = p

      let products = this.products
      if (Array.isArray(categories) && categories.length > 0) {
        const categoryDict = new Set<string>()
        categories.forEach((category) => {
          categoryDict.add(category)
        })

        products = products.filter((product) => {
          return product.categories.some((category) => {
            return categoryDict.has(category)
          })
        })
      }

      if (keyword) {
        const sanitizedKeyword = keyword.toLowerCase()
        products = products.filter((product) => {
          return (
            product.name.toLowerCase().includes(sanitizedKeyword) ||
            (product.description && product.description.toLowerCase().includes(sanitizedKeyword)) ||
            (product.meta && product.meta.toLowerCase().includes(sanitizedKeyword))
          )
        })
      }

      const totalItems = products.length

      const last = page * total_items
      const first = last - total_items

      products = products.slice(first, last)

      return {
        success: new Status("success search product", ACTION_SUCCESS),
        data: {
          summary: {
            page,
            total_items: totalItems
          },
          items: products
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async GetProduct(p: GetProductParam): Promise<GetProductResult> {
    try {
      const product = this.products.find((product) => {
        return product.slug === p.slug
      })
      if (!product) {
        return {
          error: new Status("product not available", RESOURCE_NOTFOUND),
        }
      }

      return {
        success: new Status("success get product", ACTION_SUCCESS),
        data: product
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

}
