import * as React from "react"
import { Outlet } from "react-router-dom"
import { Header } from "@allied/react-web/Header"
import { Footer } from "@allied/react-web/Footer"
import { ToastContainer } from "../components/Toast"
import { Button } from "../components/Contact/Button"
import { ProductService } from "../services/static/Product"

const productService = new ProductService()

export default function Guest(): React.JSX.Element {
  const [categories, setCategories] = React.useState([])

  const [search, setSearch] = React.useState({
    show: false,
    loading: false,
    items: []
  })
  const [suggestionItems] = React.useState(["Hydraulic oil", "Engine oil", "Arnica"])

  async function searchCategories() {
    const searchCategories = await productService.SearchCategory({})
    if (searchCategories.error) {
      return
    }

    setCategories(searchCategories.data.items.map((category) => {
      return {
        content: <>{category.name}</>,
        to: `/category/${category.slug}`,
        icon: category.icon
      }
    }))
  }

  async function searchProduct(filter?: Record<string, any>) {
    const keyword = filter.keyword || ""
    if (keyword.length < 3) {
      return
    }

    setSearch((prevState) => {
      return {
        ...prevState,
        show: true,
        loading: true
      }
    })
    const searchProduct = await productService.SearchProduct(filter)
    setSearch((prevState) => {
      return {
        ...prevState,
        loading: false
      }
    })

    if (searchProduct.error) {
      return
    }
    setSearch((prevState) => {
      return {
        ...prevState,
        items: searchProduct.data.items.map((item) => {
          return {
            name: item.name,
            url: `/product/${item.slug}`
          }
        })
      }
    })
  }

  function clearSearch() {
    setSearch((prevState) => {
      return {
        ...prevState,
        show: false,
        items: []
      }
    })
  }

  React.useEffect(() => {
    searchCategories()
  }, [])

  return (
    <>
      <Header.AlliedMarine
        logoUrl="/"
        logoImage="/image/acsm-logo.png"
        logoDescription={<>ACS MARINE & <br />SHIPPING PTE LTD</>}
        sublogoImage="/image/eni-logo.png"
        sublogoDescription={<>authorized distributor <br /> of <span className="font-bold">eni</span> lubricants</>}
        menuItems={[
          {
            content: <>Home</>,
            to: "/",
            items: []
          },
          {
            content: <>Products</>,
            to: "/product",
            items: [
              {
                content: <>All Products</>,
                to: "/product",
                items: categories
              }
            ]
          },
          {
            content: <>Contact Us</>,
            to: "/contact",
            items: []
          },
        ]}
        searchLoading={search.loading}
        searchShow={search.show}
        searchItems={search.items}
        suggestionItems={suggestionItems}
        onSelectItem={() => {
          clearSearch()
        }}
        onSearchClose={() => {
          clearSearch()
        }}
        onSearch={({ keyword }: { keyword: string }) => {
          searchProduct({
            page: 1,
            total_items: 5,
            keyword
          })
        }}
        sticky />
      <ToastContainer />
      <div className="font-verdana sm:!font-poppins min-h-screen">
        <Outlet />
      </div>
      <Footer.AlliedMarine
        logoImage="/image/acsm-logo.png"
        logoUrl="/"
        logoDescription={<>ACS MARINE & <br />SHIPPING PTE LTD</>}
        sublogoImage="/image/eni-logo.png"
        sublogoDescription={<>authorized distributor <br /> of <span className="font-bold">eni</span> lubricants</>}
        menuItems={[
          {
            content: <>Products</>,
            to: "/product",
            items: categories.map((category) => {
              return {
                content: category.content,
                to: category.to,
              }
            })
          },
          {
            content: <>Help</>,
            items: [
              {
                content: <>Contact Us</>,
                to: "/contact",
              },
              {
                content: <>Privacy Policy</>,
                to: "/privacy-policy",
              },
              {
                content: <>General Terms & Conditions</>,
                to: "/terms-conditions",
              }
            ]
          },
        ]}
      />
      <Button />
    </>
  )
}
