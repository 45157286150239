import * as React from "react"
import { useParams } from "react-router-dom"
import { Meta } from "@allied/react-web/Meta"
import { ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Link } from "@allied/react-web/Router"
import { useHashFragment } from "@allied/react-web/hooks"
import { useAutoPosition } from "../hooks"
import Hero from "../components/Contact/Hero"
import { ProductService } from "../services/static/Product"

const productService = new ProductService()

type CategoryItem = {
  name: string
  description?: string
  slug: string
  icon: any
}

type ProductItem = {
  name: string
  description?: string
  slug: string
  thumbnail: any
}

export default function CategoryDetail(): React.JSX.Element {
  const [category, setCategory] = React.useState<CategoryItem>(null)
  const [products, setProducts] = React.useState<ProductItem[]>([])
  const { slug } = useParams()

  React.useEffect(() => {
    async function getCategory() {
      const getCategory = await productService.GetCategory({
        slug
      })
      if (getCategory.error) {
        return
      }

      setCategory(getCategory.data)
    }

    async function searchProducts() {
      const searchProducts = await productService.SearchProduct({
        categories: [slug]
      })
      if (searchProducts.error) {
        return
      }

      setProducts(searchProducts.data.items)
    }

    getCategory()
    searchProducts()
  }, [slug])

  useAutoPosition()
  useHashFragment()

  return (
    <>
      {
        !category &&
        <div className="min-h-screen flex flex-col justify-center items-center">
          <div className="h-screen flex flex-col justify-center items-center">
            <div className="my-4 flex flex-col gap-4 justify-center items-center">
              <h1 className="text-8xl">
                404
              </h1>
              <h2 className="text-2xl">
                Oops! Page not found
              </h2>
            </div>
            <div className="my-6">
              <ButtonLink to="/" size="lg">
                Back to Homepage
              </ButtonLink>
            </div>
          </div>
        </div>
      }

      {
        category &&
        <>
          <Meta>
            {{
              title: category.name,
              description: category.description
            }}
          </Meta>

          <Container size="md">
            <div className="px-0 py-4 lg:!p-16 xl:!p-24">
              <h1 className="text-2xl lg:!text-5xl text-acsm-red mb-6">
                {category.name}
              </h1>
              {
                category.description &&
                <p className="text-base lg:!text-lg mb-3">
                  {category.description}
                </p>
              }
              <hr className="my-2 border-black" />
              <div className="grid grid-cols-12 auto-rows-fr gap-4 my-8 lg:!my-14">
                {
                  products.map((product, i: number) => {
                    return (
                      <React.Fragment key={`product-item-${i}`}>
                        <div className={
                          "col-span-6 2xl:!col-span-4 " +
                          "relative w-full h-full rounded-3xl " +
                          "before:absolute before:w-full before:h-full before:rounded-3xl before:bg-acsm-primary before:z-[-1] before:content-[''] " +
                          "before:top-0 before:transition-all before:duration-500 before:hover:h-[90%] before:hover:top-[5%]"
                        }>
                          <Link to={`/product/${product.slug}`} className="flex flex-col lg:!flex-row justify-start items-center gap-4 p-6">
                            <img src={product.thumbnail} alt="product"
                              className="w-32 h-32 lg:!w-28 lg:!h-28 xl:!w-42 xl:!h-42" />
                            <span className="flex flex-col gap-2 justify-start items-start">
                              <span className="text-base lg:!text-2xl text-acsm-red">
                                {product.name}
                              </span>
                              {
                                product.description &&
                                <p className="text-sm lg:!text-lg">
                                  {product.description}
                                </p>
                              }
                            </span>
                          </Link>
                        </div>
                      </React.Fragment>
                    )
                  })
                }
              </div>
            </div>

            <Hero />
          </Container>
        </>
      }
    </>
  )
}