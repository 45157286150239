import * as React from "react"
import { To } from "react-router-dom"
import { FaEnvelope } from "react-icons/fa6"
import { Link } from "@allied/react-web/Router"

type ButtonProps = {
  to?: To
  children?: React.ReactNode
}

export function Button(props: ButtonProps): React.JSX.Element {
  const {
    to = "mailto:sales@acsmarine.sg",
  } = props

  return (
    <Link to={to} aria-label="Email"
      className="fixed right-4 bottom-4 lg:!right-6 lg:!bottom-6 h-16 w-16 lg:!h-8 lg:!w-24 rounded-full lg:!rounded-3xl bg-acsm-primary border-1 border-black flex flex-row justify-start items-center gap-2 px-4 py-2">
      <FaEnvelope className="block h-8 w-8 lg:!h-4 lg:!w-4" aria-hidden="true" />
      <span className="hidden lg:!flex">Email</span>
    </Link>
  )
}
